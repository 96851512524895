// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Avatar from "../catalyst/Avatar.re.mjs";
import * as Navbar from "../catalyst/Navbar.re.mjs";
import * as Dropdown from "../catalyst/Dropdown.re.mjs";
import * as LoginLink from "../molecules/LoginLink.re.mjs";
import * as LogoutLink from "../molecules/LogoutLink.re.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.re.mjs";
import * as WaitForMessages from "../shared/i18n/WaitForMessages.re.mjs";
import * as React from "@headlessui/react";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.re.mjs";
import * as NavViewer_viewer_graphql from "../../__generated__/NavViewer_viewer_graphql.re.mjs";

import { css, cx } from '@linaria/core'
;

import { t } from '@lingui/macro'
;

var convertFragment = NavViewer_viewer_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(NavViewer_viewer_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NavViewer_viewer_graphql.node, convertFragment);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function NavViewer(props) {
  var match = use(props.viewer);
  var user = match.user;
  return JsxRuntime.jsx(WaitForMessages.make, {
              children: (function () {
                  return Core__Option.getOr(Core__Option.map(user, (function (user) {
                                    return JsxRuntime.jsxs(React.Menu, {
                                                children: [
                                                  JsxRuntime.jsxs(Dropdown.DropdownButton.make, {
                                                        as: Navbar.NavbarItem.make,
                                                        children: [
                                                          Core__Option.getOr(user.lineUsername, ""),
                                                          JsxRuntime.jsx(Avatar.make, {
                                                                src: user.picture,
                                                                square: true
                                                              })
                                                        ]
                                                      }),
                                                  JsxRuntime.jsxs(Dropdown.DropdownMenu.make, {
                                                        className: "min-w-64",
                                                        anchor: "bottom end",
                                                        children: [
                                                          JsxRuntime.jsx(Dropdown.DropdownItem.make, {
                                                                href: "/events",
                                                                children: JsxRuntime.jsx(Dropdown.DropdownLabel.make, {
                                                                      children: t`My Events`
                                                                    })
                                                              }),
                                                          JsxRuntime.jsx(Dropdown.DropdownItem.make, {
                                                                href: "/settings/profile",
                                                                children: JsxRuntime.jsx(Dropdown.DropdownLabel.make, {
                                                                      children: t`Settings`
                                                                    })
                                                              }),
                                                          JsxRuntime.jsx(Dropdown.DropdownDivider.make, {}),
                                                          JsxRuntime.jsx(Dropdown.DropdownItem.make, {
                                                                children: JsxRuntime.jsx(Dropdown.DropdownLabel.make, {
                                                                      children: JsxRuntime.jsx(LogoutLink.make, {})
                                                                    })
                                                              })
                                                        ]
                                                      })
                                                ]
                                              });
                                  })), JsxRuntime.jsx(LoginLink.make, {}));
                })
            });
}

var make = NavViewer;

var $$default = NavViewer;

export {
  Fragment ,
  make ,
  $$default as default,
}
/*  Not a pure module */
